<template>
    <div class="location-show post provider btm-menu show-bulletpoints-normal">
        <Breadcrumb :background="true" :content="breadcrumb"/>

        <div :class="['profile', typeClass]">
            <div class="container-fluid">
                <div class="profile-main">
                    <div v-if="backgroundImg!==null" class="bg-img mr-3" :style="backgroundImg"></div>
                    <div v-else :alt="subCategory" class="cat-icon-container mt-3"><img class="cat-icon" :src="icon">
                    </div>
                    <div class="profile-main__info">
                        <h5 v-if="category!==null" class="mt-0">{{category}}: {{subCategory}}</h5>
                        <div class="profile-main__info-title title">
                            <h3 class="do-not-translate">{{ title }}</h3>
                            <share-button class="share-btn"/>
                        </div>

                        <info-block target="desktop" :content="institution" :infos="infoBlockInfos"/>

                    </div>
                </div>
            </div>
        </div>

        <div class="section about">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row">

                            <info-block class="col-12 d-lg-none mb-5" :content="institution" target="mobile" :infos="infoBlockInfos"/>

                            <div class="col-12 col-lg-6">
                                <div class="about-content d-lg-none">
                                    <h5 class="post-heading">Was machen wir?</h5>
                                    <p v-if="showMoreDescription" v-html="shortDescription"></p>
                                    <p v-else v-html="description"></p>
                                    <button v-if="shortDescription!==''" @click="showMoreDescription = !showMoreDescription" class="btn btn-block mt10 btn-link text-primary more-btn">
                                        {{ showMoreDescription ? "Mehr anzeigen" : "Weniger anzeigen" }}
                                    </button>
                                </div>
                                <div class="about-content d-none d-lg-block" v-if="description!==null">
                                    <h5 class="post-heading">Was machen wir?</h5>
                                    <p v-html="description"></p>
                                </div>
                            </div>

                            <div class="col-12 col-lg-6" v-if="schlagworte!==null">
                                <div class="about-chips">
                                    <h5 class="post-heading">Kurz zusammengefasst</h5>
                                    <h4 class="chip" v-for="(item, i) in schlagworte" :key="i">{{ item }}</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-3 ml-auto">
                        <div class="card-simple mb20 mt40 mt-lg-0" v-if="weitereInfos!==''">
                            <h5 class="card-simple-title">Weitere Infos</h5>
                            <p class="text-black" v-html="weitereInfos"></p>
                        </div>

                        <a class="btn btn-block btn-w-icon icon-left btn-secondary" target="_blank" :href="flyerLink" v-if="flyerLink!==null">
                            <span class="material-icons-outlined" aria-hidden="true">file_download</span>
                            Flyer herunterladen
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <institution-angebot-block v-if="institution!==null" :content="institution"/>

        <div class="section pt-lg-0">
            <div class="container-fluid">
                <div class="row">

                    <div class="col-12 col-lg-6">
                        <detail-map v-if="institution !== null && address !== null" :content="institution" :addressObj="address"></detail-map>
                    </div>

                    <div class="col-12 col-lg-6">
                        <connection-card v-if="$isMobileScreen" :address="connectionAddress"/>
                        <contact-block class="mt40 mt-lg-0" :contentId="institution.id" :contact="contactInfo" v-if="contactInfo!=null && institution!==null"/>
                    </div>
                </div>
                <connection-card v-if="!$isMobileScreen" :address="connectionAddress"/>
            </div>
        </div>

        <collection-block v-if="institution!==null" :content="institution"/>

    </div>
</template>

<script>
import { getInstitution } from '@/api/institution';
import {getSubCategory } from '@/utils/lupe-helpers';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import ShareButton from '@/components/controls/ShareButton.vue';
import { getFieldValues, showLoader, hideLoader, isset, json_decode, getFlyerLink } from '@/utils/helpers';

export default {
    name: 'OrteDetail',
    mixins: [screenSizeMixin],
    components: {
        ShareButton: () => import('@/components/controls/ShareButton.vue'),
        InfoBlock: () => import('@/components/controls/InfoBlock.vue'),
        DetailMap: () => import('@/components/controls/DetailMap.vue'),
        ContactBlock: () => import('@/components/controls/ContactBlock.vue'),
        AngebotCard: () => import('@/components/cards/Angebot.vue'),
        ConnectionCard: () => import('@/components/cards/Connection.vue'),        
        MerklisteCard: () => import('@/components/cards/Merkliste.vue'),
        Breadcrumb: () => import('@/components/layout/Breadcrumb.vue'),
        CollectionBlock: () => import('@/components/controls/CollectionBlock.vue'),
        InstitutionAngebotBlock: () => import('./components/InstitutionAngebotBlock.vue'),
    },
    data() {
        return {
            institution: null,
            showMoreDescription: false,
        }
    },
    watch: {
      id(newId, oldId) {
        if (newId!=oldId) {
          this.getInstitutionContent();
          this.isActiveLocation=true;
          this.$scrollTo();
      }
  }
},
created() {
  this.getInstitutionContent();
  this.isActiveLocation=true;
},
computed: {
    icon(){
        switch(this.category) {
        case 'Bildung':
            return '/assets/icons/kategorien/bildung.png';
            break;
        case 'Freizeit':
            return 'assets/icons/kategorien/freizeit.png';
            break;
        case 'Ehrenamt & Engagement':
            return '/assets/icons/kategorien/ehrenamt.png';
            break;
        case 'Beratung':
            return '/assets/icons/kategorien/beratung.png';
            break;
        case 'Sprache':
            return '/assets/icons/kategorien/sprache.png';
            break;
        case 'Wohnen':
            return '/assets/icons/kategorien/wohnen.png';
            break;
        default:
            return '/assets/RefugeeCompass_bg.png';        } 
        },
        backgroundImg() {
            if(typeof this.logo !== 'undefined' && this.logo !== null){
                return 'backgroundImage: url("'+this.logo+'")';  
            }
            return null;
        },
        typeClass(){
            if(this.category !== null) {
                let cat = this.category + '-style';
                return cat!==null?cat.replace(/\s/g, '').replace(/[^A-Za-z0-9\-_]/g, ''):'';
            }
            else {
                return 'no-cat';
            }
        },
        id() {
            return this.$route.params.id;
        },
        title(){
            if(this.institution!=null){
              return this.institution.title;
          }
          return "";
      },
      description(){
        var desc = getFieldValues(this.institution,'description');
        return desc!=null?desc:'';
    },
    breadcrumb() {
        if(isset(this.category) && isset(this.subCategory)){
            return "Einrichtungen" + " | " + this.category + " | " + this.subCategory; 
        }
        return "Einrichtungen";
    },
    infoBlockInfos(){
        var object = {};
        object.address = this.address;
        object.appointments = getFieldValues(this.institution,'appointments');

        object.linkTo = {
            url: getFieldValues(this.institution,'url'),
            facebook: getFieldValues(this.institution,'facebook'),
            instagram: getFieldValues(this.institution,'instagram'),
            twitter: getFieldValues(this.institution,'twitter'),
            youtube: getFieldValues(this.institution,'youtube'),
        }
        return object;
    },
    connectionAddress(){
        if(this.address!=''){
            return this.address.street+', '+this.address.zipcode+' '+this.address.city;
        }
        return '';
    },
    address(){
        var address = getFieldValues(this.institution,'adresse');
        if(address!==null && address!==''){
            if(this.category!==null){
                address.category=this.category;
                address.color="#2E55A5";
            }
            return address;
        }
        return '';
    },
    category(){
        var value = getFieldValues(this.institution,'kielrefugeecompass');
        if(value!=null){
            if(Array.isArray(value) && value.length>0){
                return value[0];
            }
            return value;
        }
        return null;
    },
    subCategory(){
        if(isset(this.category)){
            return getSubCategory(this.institution,this.category);
        }
        return null;
    },
    teaserImages(){
        var pictures = getFieldValues(this.institution,'teaser-bild');
        if(pictures != null){
            if(Array.isArray(pictures)){
                return pictures;
            }
            else{
                return [pictures];
            }
        }
        return null;
    },
    logo(){
        if(getFieldValues(this.institution,'logo')!==null){
            var logo = getFieldValues(this.institution,'logo');
            logo = json_decode(logo);
            if(typeof logo.path!=='undefined'){
                return this.$backendUrl+logo.path;
            }
            return null;
        }
    },
    contactInfo(){
        var contact = getFieldValues(this.institution,'kontaktinformationen');
        return contact!==null?contact:null;
    },
    shortenedUrl(){
        if(this.url!=null){
          return this.url.length>26?this.url.substring(0,26)+' ...':this.url;
      }
  },
  currentPath(){
    return window.location.href;
},
schlagworte(){
    var value = getFieldValues(this.institution,'kielrefugeecompass_-_schlagwrter');
    if(value!=null){
        if(!Array.isArray(value)){
            var temp = value;
            value = [temp];
        }
        return value;
    }
    return null;
},
weitereInfos(){
    var value = getFieldValues(this.institution,'weitere_infos');
    return value!=null?value:'';
},
flyerLink(){
    return getFlyerLink(getFieldValues(this.institution,'flyer_hochladen'),this.$backendUrl);
},
showShareBtnInTitle(){
    if(this.$isMobileScreen){
        return true;
    }
    return false;
},
shortDescription() {
    if (this.description.length > this.$shortDescriptionWordCount) {
        this.showMoreDescription = true;
        return this.description.substring(0, this.$shortDescriptionWordCount) + '...';
    }
    return '';
},
},
methods:{
    json_decode,
    getFieldValues, 
    showLoader, 
    hideLoader, 
    getInstitutionContent() {
        this.loader = this.showLoader(this.loader);
        getInstitution(this.id, this.$store.getters.getLanguage)
        .then(response => {
            this.institution = response.data;
        })
        .finally(() => {
          this.loader = this.hideLoader(this.loader);
      });
    },
}
}
</script>
<style lang="scss">
@import '@/scss/_mixins.scss';
@import '@/scss/_variables.scss';

.provider{
    margin-bottom: calc(#{$footer-sm-size} + #{$mobile-menu-size});
    background-color: #FFF;

    @media(max-width:991px){
        margin-bottom: 110px;
    }

    .mobile--scrolling{
      margin-left: 0px;
      padding-right: 15px;
  }

  .profile{
    .container-fluid{
        @media (max-width: 991px) {
            padding-left: 0px;
            padding-right: 0px;
            margin-left: 0px;
            margin-right: 0px;
        }

        .profile-main__info {
            max-width: none;
            margin-left: 30px;

            @media (max-width: 1360px) {
              min-width: 540px;
              margin-right: 60px;
          }

          @media (max-width: 991px) {
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
        }

        @media (max-width: 555px) {
            min-width: auto;
        }

        .info-list {
            margin-right: 20px;
        }

        h5 {
            @media (max-width: 991px) {
                margin-bottom: 0px;
            }
        }

        h5, h3 {
            color: $text-color;
        }
    }
}
}

.offers {
    margin-bottom: 0;
}

}

.Beratung-style {
    background-color: #FEE0C5 !important;
}

.Sprache-style {
    background-color: #F6A7AB !important;
}

.Bildung-style {
    background-color: #FCD770 !important;
}

.Freizeit-style {
    background-color: #C7E4FF !important;
}

.Wohnen-style {
    background-color: #DED5F3 !important;
}

.EhrenamtEngagement-style {
    background-color: #A4E6C7 !important;
}

.share-btn {
    @media(max-width:991px){
        margin-left: auto !important;
    }
    span {
        color: $text-color !important;
        border: 1px solid $white-color;
        border-radius: 50%;
        background-color: $white-color;
    }
}

.title {
    justify-content: normal !important;

    h3 {
        margin-right: 15px;
    }
}

.cat-icon-container {
    border: 5px solid grey;
    border-radius: 50%;
    padding: 20px;
    background-color: #FFF;
}

.cat-icon {
    width: 122px;
    height: 122px;
}

.share-btn {
    span {
        @media(max-width:991px){
            padding: 5px !important;
            background: rgba(255,255,255,.7);
        }
    }
}

.info {
    @media(max-width: 991px){
        margin-top: 0 !important;
    }
}

.more-btn:hover, .more-btn:active, .more-btn:focus {
    background: none;
}

.post-heading {
    font-size: 24px;
}

.show-bulletpoints-normal {
    ul{
        padding: 0 !important;
    }
}

.bg-test {
    height: 142px;
    width: 142px;
    background-image: url("/assets/logo/WolfsburgerLupe.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: 5px solid #FFFFFF;
    border-radius: 100px;
}

.about-chips {
    @media(min-width:992px){
        padding-left: 40px;
        margin-top: 0 !important;
    }
}

.location-show .bg-img {
    min-width: 142px;
    min-height: 142px;
    width: 142px;
    height: 142px;
    border: 5px solid #FFF;
    background-color: #FFF;
    background-size: contain;
    background-repeat: no-repeat;
    background-position:center;
    border-radius: 50%;
}



</style>