<template>
 <div class="btn-share btn-icon">
    <navigator-share v-if="isItMobile && webShareApiSupported" v-bind:url="currentPath" v-bind:title="'Kiel.RefugeeCompass - Bildungs-, Beratungs- und Freizeitangebote für Geflüchtete in Kiel'">
        <template v-slot:clickable>
            <button class="btn btn-link"><span class="material-icons-outlined" aria-hidden="true">share</span></button>
        </template>
    </navigator-share>

    <a :href="shareViaEmail" v-else class="btn btn-link">
        <span class="material-icons-outlined" aria-hidden="true">share</span>
    </a>

</div>

</template>
<script>
    import NavigatorShare from 'vue-navigator-share';
    import { isMobile } from 'mobile-device-detect';


    export default {
        name: "shareButton",
        components: {
            NavigatorShare
        },
        data() {
            return {
                isItMobile: isMobile ? true : false,
            }
        },
        computed: {
            currentPath(){
                return window.location.href;
            },
            webShareApiSupported() {
                return navigator.share
            },
            shareViaEmail(){
                return "mailto:?body="+this.currentPath;
            },
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/scss/_mixins.scss';
    @import '@/scss/_variables.scss';

    .btn-share {
      margin-left: 20px;

      .material-icons-outlined{
        color: #fff;
        padding: 10px;

        @media (max-width: 991px) {
            padding: 0px;
        }


        &:hover{
            padding: 10px;
            border-radius: 50%;
            border:1px solid #fff;

            @media (max-width: 991px) {
                padding: 0px;
                border: none;
                color: #CECECE;
            }
        }
    }
}

.btn {
    background: none !important;
}

</style>