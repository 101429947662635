import request from '@/utils/request';

export function getInstitutionContents(id,query) {
	return request({
		url: '/institutions/'+id+'/contents',
		method: 'get',
		params: query,
	});
}

export function getInstitutionCollections(id) {
	return request({
		url: '/institutions/'+id+'/collections',
		method: 'get',
	});
}

export function getInstitution(id, langcode = "de") {
	return request({
		url: '/institution/de/'+id,
		method: 'get',
	});
}